// базовые подключения

@import "vars";
@import "mixins";
@import "fonts";
@import "settings";
// подключения компонентов страницы
@import "./components/header";


.burger{
    @include burger();
    display: none;
}

html, body{
    font-size: 16px;
}
.site-container{
    position: relative;
}


.block-descr{
font-size: 1rem;
color: #fff;
max-width: 920px;
}


.block-title{
    font-size: 36px;
    font-weight: 600;
    padding-top: 120px;
    margin-bottom: 80px;
    text-transform: uppercase;
    &--blue{
        color: $blue;
    }

    &--white{
        color: #fff;
    }
}
.header{
    position: absolute;
    top: 0;
    width: 100%;
    padding: 50px 0;
    z-index: 3;
    .header__inner{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    nav{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #fff;
    }

    &__logo{
        height: 37px;
        margin-right: 40px;
    }

    .header__menu{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 25px;
        a{
            font-size: 1.125rem;
            font-weight: 500;
            color: #fff;
            white-space: nowrap;
        }

        li{
            margin-right: 30px;
            
            &:last-child{
                margin-right: 0;
            }
        }
    }

    .phone{
        color: #Fff;
        text-transform: uppercase;
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        img{
            width: 25px;
            height: 25px;
            margin-right: 10px;
            margin-top: -6px;
        }
    }


}


.main__screen{
    width: 100%;
    min-height: 950px;
    height: 100vh;
    background-image: url('../img/background-main.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
   
    &-inner{
        padding-top: 340px;
        z-index: 2;
        position: relative;
    }

    &-subtitle{
        font-weight: 500;
        font-size: 1.625rem;
        margin-bottom: 40px;
    }

    &-title{
        font-weight: 600;
        font-size: 3rem;
        max-width: 950px;
        text-transform: uppercase;

    }


}

.btn{
    background-color: #fff;
    display: inline-block;
    max-width: 375px;
    padding: 25px 100px;
    font-size: 1.125rem;
    font-weight: 600;
    color: $blue;
    border-radius: 100px;
    text-transform: uppercase;
    -webkit-box-shadow: -1px -2px 44px -2px rgba(255, 255, 255, 0.53);
    -moz-box-shadow: -1px -2px 44px -2px rgba(255, 255, 255, 0.53);
    box-shadow: -1px -2px 44px -2px rgba(255, 255, 255, 0.53);
    margin-top: 50px;
}

.about{
    padding-bottom: 130px;
    &__inner{
        display: flex;
        justify-content: space-between;
    }
    &__col{
        width: 48%;
        p{
            font-size: 1rem;
            font-weight: 400;
            color: #001d2b;
            a{
                color: #001d2b;
                text-decoration: underline;
            }
        }

        &-title{
            font-size: 1.125rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        &-img{
            margin-top: 40px;
            max-width: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .information{
            max-width: 100%;
            padding: 40px;
            background-color: #f8f8f8;
            border-radius: 10px;
            display: flex;
            p{
                margin: 0;
            }
            img{
                margin-right:30px;
            }
            margin-bottom: 50px;
        }

        ul{
            list-style: none;
            padding: 0;
            li::before{
                content: '';
                display: inline-block;
                width: 7px;
                height: 7px;
                background: $blue;
                border-radius: 100%;
                margin-right: 8px;
            }

            li{
                margin-bottom: 20px;
                line-height: 1.3;
            }
        }
    }

   
}


.direction{
    
    background: rgb(0,49,73);
    background: linear-gradient(0deg, rgba(0,49,73,1) 0%, rgba(0,29,43,1) 100%);

    &__inner{
        padding-bottom: 190px;
    }

    &__title{
        font-size: 1.125rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        padding-top: 50px;
    }

    &__items{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }

    &__item{
        color: #fff;
        max-width: 350px;
        flex: 0 0 100%;
        margin-top: 50px;
        padding-right: 20px;
    }


}


.consultants{
    padding-bottom: 30px;
    &__inner{
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
    }

    
    &__col{
        width: 65%;
        padding-right: 100px;
        &:last-child{
            width: 35%;
            padding-right: 0;
        }

        .col-img{
            max-width: 400px;
        }
        ul{
            list-style: none;
            padding: 0;
            li::before{
                content: '';
                display: inline-block;
                width: 6px;
                height: 6px;
                background: $blue;
                border-radius: 100%;
                margin-right: 8px;
                margin-bottom: 2px;
            }
    
            li{
                margin-bottom: 20px;
                line-height: 1.3;
                a{
                    text-decoration: underline;
                    color: #000;
                }
            }
        }

        


    }

    .links{
        display: flex;
        flex-wrap: wrap;
        a{
            display: flex;
            align-items: center;
            padding: 15px 20px;
            background-color: #f8f8f8;
            border-radius: 50px;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #000;
            img{
                margin-right: 15px;
            }
        }
    }
    
}


.col{

    p{
        font-size: 1rem;
    }
    &-title{
        font-weight: 600;
        font-size: 1.125rem;
        text-transform: uppercase;
        &--grey{
            color: #001d2b;
            font-size: 1.125rem;
            font-weight: 500;
            display: inline-block;
            padding: 20px 0px;
        }
    }

    &-img{
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    
}

.footer__menu li a{
    white-space: nowrap;
}

.patents{
    padding: 80px 0px;
    background-color: #f8f8f8;
    .block-title{
        padding-top: 0;
    }
    &__item{
        display: flex;
        &-col{
            width: 80%;
            padding-right: 100px;
            &:last-child{
                width: 20%;
                padding-right: 0;
            }
        }
        &-title{
            font-size: 1.125rem;
            color: #001d2b;
        }

        margin-bottom: 35px;

        &-more{
            font-weight: 500;
            color: #003d5a;
            text-decoration: underline;
            display: flex;
            align-items: center;
            margin-top: 40px;
            img{
                margin-left: 15px;
            }
        }
    }

    p{
        font-weight: 400;
    }
}


.banner{
    width: 100%;
    height: 570px;
    background-image: url('../img/for.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
}

.for{
    margin-top: 80px;
    &__inner{
        display: flex;
        justify-content: space-between;
    }
    .block-title{
        padding-top: 0;
    }
    p{
        max-width: 670px;
        font-weight: 400;
        line-height: 1.4;
        color: #000;
    }

    .block-descr{
        color: #000;
        
    }

    &__inner{
        padding-bottom: 80px;
    }

    .for-btn{
        background-color: #003d5a;
        color: #fff;
        font-size: 16px;
        border-radius: 100%;
        width: 170px;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
        text-transform: uppercase;
    }
}


.contact{
    padding-bottom: 120px;
    background: #003149;
    background: linear-gradient(0deg, #003149 0%, #001d2b 100%);
    &__inner{
        display: flex;
        justify-content: space-between;
    }

    &__col{
        width: 48%;
    }

    &-link{
        display: flex;
        font-size: 1;
        margin-bottom: 30px;
        color: #fff;
        line-height: 1.4;
        .img-wrap{
            padding-top: 4px;
            width: 24px;
            margin-right: 20px;
        }
       
    }

    &__social{
        margin-top: 45px;
        a{
            margin-right: 15px;
        }
    }

    .contact__form{
        display: flex;
        flex-direction: column;
        input{
            appearance: none;
        }

        .input-default, textarea{
            font-size: 1.125rem;
            background-color: transparent;
            color: #6a7f8b;
            border-radius: 50px;
            padding: 25px 50px;
            border: 1px solid #003d5a;
            outline: none;
            margin-bottom: 20px;
            resize: none;
            
            &::placeholder{
                color: #6a7f8b;
            }
            
        }

        .submit-btn{
            text-transform: uppercase;
            border-radius: 50px;
            background-color: #fff;
            font-size: 1.125rem;
            font-weight: 600;
            padding: 25px 50px;
            border-radius: none;
            border: none;
            outline: none;
            color: #003d5a;
            max-width: 375px;
            cursor: pointer;
            -webkit-box-shadow: -1px -2px 57px 13px rgba(255, 255, 255, 0.25);
            -moz-box-shadow: -1px -2px 57px 13px rgba(255, 255, 255, 0.25);
            box-shadow: -1px -2px 57px 13px rgba(255, 255, 255, 0.25);
        }
    }
}


.footer{
    background-image: url('../img/footer.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &__inner{
        padding: 80px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logo{
        max-width: 325px;
    }

    &__menu{
        display: flex;
        list-style: none;
        
        a{
            color: #fff;
            font-size: 1.125rem;
        }
        li{
        margin-right: 30px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}


.main__screen-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 1;
}


@include tablet{

    html, body{
        font-size: 14px !important;
    }

    p{
        font-size: 1em;
    }
    .header__menu{  
        background: rgb(0,49,73);
        background: linear-gradient(0deg, rgba(0,49,73,1) 0%, rgba(0,29,43,1) 100%);
        
        flex-direction: column;
        width: 100%;
        right: 0;
        top: 50px;
        position: absolute;
        max-width: 280px;
        padding: 25px !important;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s linear;
        li{
            margin-bottom: 30px;
        }
    }

    .burger--active + .header__menu{
        visibility: visible;
        opacity: 1;
    }
    .header{

        nav{
            flex-direction: row;
            align-items: center;
        }

        .burger{
            display: block;
            margin-left: 60px;
        }

        &__logo{
            max-width: 280px;
        }
    }


    .main__screen{
        min-height: 780px;
    }

    .main__screen-inner{
        padding-top: 280px;
    }

    .main__screen-title{
        font-size: 30px;
        max-width: 600px;
    }

    .btn{
        padding: 21px 76px;
    }

    .main__screen-subtitle{
        font-size: 18px;
    }

    .container{
        max-width: 810px;
        padding: 0px 15px;
    }

    .block-title{
        padding-top: 65px;
        font-size: 30px;
        margin-bottom: 50px;
    }

    .about__inner{
        flex-direction: column;
        .about__col{
            width: 100%;
            &:first-child{
                margin-bottom: 40px;
            }
        }
    }

    .direction__item{
        img{
            max-width: 110px;
            max-height: 110px;
            width: 100%;
            height: 100%;
        }
        max-width: 240px;
    }

    .consultants__inner{
        flex-direction: column;
    }

    .consultants__col{
        padding-right: 0;
        margin-right: 0;
        width: 100%;
        &:last-child{
            display: flex;
            width: 100%;
            margin-top: 15px;
            .col-img{
                margin-right: 30px;
            }
        }
    }

    .patents__item-col{
        width: 75%;
    }

    .patents__item-col:last-child{
        width: 25%;
    }

    .for p{
        max-width: 575px;
    }

    .for .btn{
        padding: 21px 54px;
        margin-top: 40px;
    }

    .footer__inner{
        flex-direction: column;
        align-items: flex-start;
        padding: 50px 0px;
    }

    .footer__logo{
        max-width: 280px;
        margin-bottom: 10px;
    }

    .footer__menu{
        padding-left: 0;
    }

    .footer{
        background-image: url("../img/footer-tablet.jpg");
    }
}



@include mobile{
    .popup__form-wrapper{
        overflow-y: auto;
    }
    .popup__form-header{
        font-size: 16px !important;
    }
    .for .for-btn{
        margin: 0 auto;
        margin-top: 50px;
    }
    .for__inner{
        flex-direction: column;
    }

    .popup__form-content form input, .popup__form-content form textarea{
        border-radius: 25px !important;
        padding: 20px !important;
    }
    .banner{
        max-height: 350px;
    }
    .header__logo{
        margin-right: 0;
    }
    .header .phone img{
        width: 22px;
        height: 22px;
    }
    .header__menu{
        left: -16px;
        width: 100%;
        width: 200%;
        max-width: 200%;
        padding: 30px !important;
        top: 75px;
    }
    .header .header__inner{
        flex-direction: column;
        align-items: center;
        nav{
            max-width: 280px;
            width: 100%;
            justify-content: space-between;
            margin-top: 15px;
        }
    }

    .footer{
        background-image: url('../img/footer-mobile.jpg');
        .footer__inner{
            align-items: center;
        }

        .footer__menu{
            flex-direction: column;
            align-items: center;

            li{
                margin-right: 0;
                margin-bottom: 25px;
            }
        }
    }
    .main__screen-title{
        font-size: 24px;
    }
    .btn {
        padding: 20px 65px;
    }

    .main__screen-inner{
        padding-top: 240px;
    }

    .main__screen{
        min-height: 660px;
    }

    .block-title{
        font-size: 24px;
        padding-top: 60px;
        margin-bottom: 30px;
    }

    .information{
        flex-direction: column;
        img{
            margin-bottom: 25px;
        }
    }

    .about, .direction__inner{
        padding-bottom: 50px;
    }

    .consultants__col:last-child{
        flex-direction: column;
    }
    .consultants .links a{
        width: 100%;
        justify-content: center;
    }

    .patents__item{
        flex-direction: column-reverse;
    }

    .patents__item-col{
        width: 100%;
        padding-right: 0;
        margin-right: 0;
    }
    .for{
        background-position: right;
    }
    .for .btn{
        padding: 21px 47px;
    }

    .contact__inner{
        flex-direction: column;
    }

    .contact__col{
        width: 100%;
        padding-right: 0;
        margin-right: 0;
    }
}


.popup__form{
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    margin: 100px auto;
    position: relative;
    z-index: 99;
   

    &-wrapper{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        padding: 0px 15px;
        
        .popup-overflow{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 90;
            cursor: default;
        }

        .close{
            position: absolute;
            right: 15px;
            top: 15px;
            opacity: 0.3;
            z-index: 99;
        }
    }

    &-header{
        background: rgb(0,49,73);
        background: linear-gradient(0deg, rgba(0,49,73,1) 0%, rgba(0,29,43,1) 100%);
        padding: 25px 30px;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        border-radius: 8px 8px 0px 0px;
        margin-bottom: 30px;
    }

    &-content{
        padding: 0px 30px;
        form{
            display: flex;
            flex-direction: column;
            input, textarea{
                 border: none;
                outline: none;
                border-radius: 50px;
                background-color: #f8f8f8;
                padding: 20px 40px;
                resize: none;
                &::placeholder{
                    color: #777777;
                }

                margin-bottom: 20px;

                color: #262626;
            }
        }

        .submit{
            background-color: #003d5a;
            color: #fff;
            cursor: pointer;
        }
    }
}

@mixin desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (768px)) {
    @content;
  }
}
